<template>
  <v-app>
    <v-main>
      <v-container fill-height style="max-width: 1285px">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "PlayerLayout",
};
</script>

<style></style>
