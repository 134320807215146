import axios from './axios';

const login = async (payload) => {
  try {
    const res = await axios.post('auth/signin', payload);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const passwordChangeRequest = async (email) => {
  try {
    const res = await axios.post('auth/resetPasswordRequest', { email });
    return res.data;
  } catch (error) {
    return false;
  }
};

const passwordChange = async (resetToken, password) => {
  try {
    const res = await axios.post('auth/resetPassword', { resetToken, password });
    return res.data;
  } catch (error) {
    return false;
  }
};

const getUser = async () => {
  try {
    const res = await axios.get('users/profile');
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//////////////// admin functions /////////////////
const getUsers = async () => {
  try {
    const res = await axios.get('users');
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const addUser = async (payload) => {
  try {
    const res = await axios.post('users/addUser', payload);
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const updateUser = async (payload) => {
  try {
    const res = await axios.patch('users', payload);
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const uploadBackground = async (payload) => {
  try {
    const res = await axios.post('/files/uploadBackground', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res.data;
  } catch (error) {
    return false;
  }
};

const getRemoveBgAccStats = async () => {
  try {
    const res = await axios.get('users/removebg/account');
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

////////////////////////////////////////////////

const getMySpins = async () => {
  try {
    const res = await axios.get('spins/list');
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getSpin = async (id, type) => {
  try {
    const res = await axios.get(`spins/${id}/${type}`);
    return res.data;
  } catch (error) {
    return {};
  }
};

const updateHotspot = async (id, hotspots) => {
  try {
    const res = await axios.put('spins/' + id + '/updateHotspot', { hotspots });
    return res.data;
  } catch (error) {
    return false;
  }
};

const updatePanoHotspot = async (id, panoHotspots) => {
  try {
    const res = await axios.put('spins/' + id + '/updatePanoHotspot', {
      panoHotspots,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const searchSpins = async (searchQuery) => {
  try {
    const res = await axios.get('spins/search?query=' + searchQuery);
    return res.data;
  } catch (error) {
    return [];
  }
};

const updateImages = async (id, payload) => {
  try {
    const res = await axios.put('spins/' + id + '/updateImages', payload);
    return res.data;
  } catch (error) {
    return false;
  }
};

const deleteImage = async (id, payload) => {
  try {
    const res = await axios.put('spins/' + id + '/deleteImage', payload);
    return res.data;
  } catch (error) {
    return false;
  }
};

const uploadImages = async (payload) => {
  try {
    const res = await axios.post('/files/upload', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res.data;
  } catch (error) {
    return false;
  }
};

const addWatermark = async (payload) => {
  try {
    const res = await axios.post('/files/addWatermark', payload);
    return res.data;
  } catch (error) {
    return false;
  }
};

const changeBackground = async (payload) => {
  try {
    const res = await axios.post('/files/changeBackground', payload);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

const exportImages = async (payload) => {
  try {
    const res = await axios.post('/files/exportImages', payload);
    return res.data;
  } catch (error) {
    return false;
  }
};

export {
  login,
  passwordChangeRequest,
  passwordChange,
  getUser,
  getUsers,
  addUser,
  updateUser,
  uploadBackground,
  getRemoveBgAccStats,
  getMySpins,
  getSpin,
  updateHotspot,
  updatePanoHotspot,
  searchSpins,
  uploadImages,
  deleteImage,
  updateImages,
  addWatermark,
  changeBackground,
  exportImages,
};
