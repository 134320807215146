import Vue from 'vue';
import Router from 'vue-router';
import auth from '../middleware/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'mySpins',
      meta: { layout: 'default', middleware: auth },
      component: () => import('@/views/Spins'),
    },
    {
      path: '/spins',
      name: 'mySpins',
      meta: { layout: 'default', middleware: auth },
      component: () => import('@/views/Spins'),
    },
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'auth' },
      component: () => import('@/views/Auth'),
    },
    {
      path: '/resetPasswordRequest',
      name: 'resetPasswordRequest',
      meta: { layout: 'auth' },
      component: () => import('@/components/auth/ResetPasswordRequest'),
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      meta: { layout: 'auth' },
      component: () => import('@/components/auth/ResetPassword'),
    },
    {
      path: '/admin',
      name: 'admin',
      meta: { layout: 'default', middleware: auth },
      component: () => import('@/views/Admin'),
    },
    {
      path: '/spins/:id',
      name: 'edit',
      meta: { layout: 'default', middleware: auth },
      component: () => import('@/views/Spin'),
    },
    {
      path: '/spins/:id/preview',
      name: 'edit',
      meta: { layout: 'player' },
      component: () => import('@/views/SpinPreview'),
    },
    /* { path: '*', component:  } */
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    return to.meta.middleware({ next, router, to });
  }
  return next();
});

export default router;
