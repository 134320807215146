import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import router from './router/index';
import store from './store';
import DefaultLayout from '@/layouts/Default';
import AuthLayout from '@/layouts/Auth';
import PlayerLayout from '@/layouts/Player';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

Vue.use(CoolLightBox)
Vue.component('default-layout', DefaultLayout);
Vue.component('auth-layout', AuthLayout);
Vue.component('player-layout', PlayerLayout);

Vue.config.productionTip = false;

store.dispatch("getUser").then(() => {
  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});

