<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import '@/assets/main.css';
export default {
  name: "App",
  computed: {
    layout() {
      console.log(this);
      return (this.$route?.meta?.layout) + "-layout";
    },
  },
};
</script>

<style>
.v-application--wrap { background: #e1eeff;}
.title_1, .title_list { margin: 10px auto 0 auto; user-select: none; font-size: 1.25em; font-weight: 500; }

</style>
