import Vue from 'vue';
import Vuex from 'vuex';
import { login, getSpin, getUser } from '../api/index';
import axios from '../api/axios';
/* import jwt_decode from "jwt-decode";

 */

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('accessToken') || null,
    user: {},
    isLoggedIn: false,
    snackbarText: '',
    snackbar: {
      text: '',
      show: false,
    },
    loading: false,
    error: '',
    pins: [],
    panoPins: [],
    spin: {},
    preloadImages: false,
  },
  mutations: {
    SET_TOKEN: (state, payload) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload}`;
      localStorage.setItem('accessToken', payload);
      state.token = payload;
    },
    SET_USER: (state, payload) => {
      console.log('set user')
      state.user = payload;
    },
    SET_SPIN: (state, payload) => {
      state.spin = payload;
      state.pins = payload.hotspots?.map((h) => h);
      state.panoPins = payload.panoHotspots?.map((h) => h);
      state.spin.images360 = payload.images360.map(
        (img) => img + '?v=' + new Date().valueOf()
      );
      state.error = ''
    },
    SET_PINS: (state, payload) => {
      state.pins = payload;
    },
    SET_PANOPINS: (state, payload) => {
      state.panoPins = payload;
    },
    SET_PRELOAD: (state, payload) => {
      console.log('COMMIta');
      state.preloadImages = payload;
    },
    SET_SNACKBAR: (state, payload) => {
      state.snackbar = payload;
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
    SET_ERROR: (state, payload) => {
      state.error = payload;
    },
  },
  actions: {
    login: async ({ commit, dispatch }, { email, password }) => {
      const result = await login({
        email,
        password,
      });
      if (result && result.token) {
        const { token } = result;
        commit('SET_TOKEN', token);
        await dispatch('getUser');
      }
    },
    getSpin: async ({ commit, state }, { id, type }) => {
      const spin = await getSpin(id, type);
      if (state.user._id === spin.userId) {
        commit('SET_SPIN', spin);
      } else {
        commit('SET_ERROR', 'You do not have access to this spin');
      }
    },

    getUser: async ({ commit }) => {
      const user = await getUser();
      if (user) {
        commit('SET_USER', user);
      }
    },
  },
  getters: {},
});
