<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-spacer></v-spacer>
      <v-img
        @click="$router.push('/')"
        class="shrink mr-2"
        contain
        :src="require('@/assets/logo.png')"
        transition="scale-transition"
      />

      <v-menu offset-y class="logout__btn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="logout__btn"
            outlined
            color="#e1eeff"
            v-bind="attrs"
            v-on="on"
          >
            {{ user.email }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              >{{ $t("expiresAt") }} {{ checkExpiration() }}</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title
              >{{ $t("spinsLeft") }} {{ user.spinsLeft }}</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title
              >{{ $t("backgroundChangesLeft") }}
              {{ user.backgroundChangeCredits }}</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <template v-if="user.role === 'ADMIN'">
            <v-list-item @click="requestPasswordChange">
            <v-list-item-title>{{
              $t("requestPasswordChange")
            }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          </template>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>
    </v-app-bar>

    <v-main>
      <slot />
      <v-snackbar v-model="snackbar.show">
        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            {{ $t("close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  /* mounted() {
    this.$i18n.locale = "sl";
  }, */
  methods: {
    logout() {
      window.localStorage.clear();
      window.location.reload();
    },
    checkExpiration() {
      if (this.user.expiresAt) {
        const date = new Date(this.user.expiresAt);
        return date.toLocaleDateString();
      }
      return "EXPIRED";
    },
    requestPasswordChange() {
      this.$router.push("/resetPasswordRequest");
    },
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(val) {
        this.$store.commit("SET_SNACKBAR", { text: "", show: val });
      },
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.commit("SET_LOADING", val);
      },
    },
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style>
.v-toolbar__content .v-image {
  height: 42px;
}
.logout__btn {
  position: absolute !important;
  right: 21px;
  border: none !important;
  background: #3e8ad6;
}
</style>