import store from '../store';

export default function auth({ next, router, to }) {
  if (!localStorage.getItem("accessToken")) {
    return router.push({ name: "login" });
  }
  if (to.path === '/admin') {
    if (store.state.user.role !== 'ADMIN') {
      return router.push('/');
    }
  }
  return next();
}

